import { render, staticRenderFns } from "./outpatientExpenditure.vue?vue&type=template&id=064fc574&scoped=true&"
import script from "./outpatientExpenditure.vue?vue&type=script&lang=js&"
export * from "./outpatientExpenditure.vue?vue&type=script&lang=js&"
import style0 from "./outpatientExpenditure.vue?vue&type=style&index=0&id=064fc574&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064fc574",
  null
  
)

export default component.exports