<template>
  <!-- 门诊费用 -->
  <div class="outpatientExpenditure">
    <div v-if="list.length == 0 ? true : false">
      <van-empty description="无费用记录" />
    </div>
    <Scroll class="region-scroll" ref="scroll">
      <div class="expenditureitems" v-for="(item, index) in list" :key="index">
        <div class="feeitem">
          <div>科室</div>
          <div>{{ item.dept_name }}</div>
        </div>
        <div class="feeitem">
          <div>费用类型</div>
          <div>{{ item.status }}</div>
        </div>
        <div class="feeitem">
          <div>日期</div>
          <div>{{ item.visit_date }}</div>
        </div>
        <div class="detail" v-if="item.status == '收费'">
          <van-button class="detailbt" size="small" @click="viewdetail(item)">
            查看详情
          </van-button>
        </div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import { outPatientFeeInfo } from '@/network/service.js'
import { mapState } from 'vuex'
export default {
  name: 'outpatientExpenditure',
  data() {
    return {
      list: [],
    }
  },
  components: {
    Scroll,
  },
  mounted() {
    this.outPatientFeeInfoPost()
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  methods: {
    async outPatientFeeInfoPost() {
      let res = await outPatientFeeInfo({
        med_id: this.$store.state.med_id,
        id_no: this.$Base64.decode(this.cardList[0].idNo),
        card_no: this.cardList[0].cardNo,
        card_type: this.cardList[0].cardType,
      })
      if (res.code == '0') {
        this.list = res.data.root.body.detail
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
    viewdetail(item) {
      this.$router.push({
        path: '/expenditureDetail',
        query: { charge_id: item.charge_id },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.outpatientExpenditure {
  background-color: #f5f5f5f5;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .expenditureitems {
      background-color: #fff;
      margin: 1rem 0rem;
      padding: 0.5rem 0.7rem;
      .feeitem {
        height: 2rem;
        display: flex;
        justify-content: space-between;
      }
      .detail {
        text-align: right;
        .detailbt {
          color: #fff;
          border-radius: 2rem;
          background: @darkColor;
        }
      }
    }
  }
}
</style>
